import React, { useEffect, useState, useMemo } from "react"
import moment from "moment"
import {
    BarChart,
    ScatterChart,
    Label,
    Bar,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Brush,
    Cell,
    Text,
} from "recharts"

export const SampleSeq = ({ importData }) => {
    return (
        <>
            <div>
                <div className="responsive-container-xs">
                    <ResponsiveContainer width={"100%"} height={"100%"}>
                        <BarChart
                            data={importData}
                            layout="vertical"
                            margin={{ left: 30 }}
                        >
                            <XAxis
                                // hide
                                axisLine={false}
                                tick={false}
                                // dataKey="amax"
                                type="number"
                            >
                                <Label value="Count" />
                            </XAxis>
                            <YAxis
                                yAxisId={0}
                                dataKey="trial_name"
                                type="category"
                            >
                                <Label
                                    value="Trial or PD"
                                    angle={-90}
                                    position="left"
                                    style={{ textAnchor: "middle" }}
                                />
                            </YAxis>
                            <YAxis
                                tick={{
                                    fontWeight: "800",
                                }}
                                orientation="right"
                                yAxisId={1}
                                dataKey="amax"
                                type="category"
                                axisLine={false}
                                tickLine={false}
                            />

                            <Bar dataKey="amax" fill="#f6a192" name="late" />
                            <Legend />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    )
}
