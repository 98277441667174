import React, { PureComponent } from "react"
import { Treemap, Legend } from "recharts"
import { Row } from "react-bootstrap"

const data = [
    {
        name: "axis",
        children: [{ name: "Axes", size: 10 }],
    },
    {
        name: "controls",
        children: [{ name: "AnchorControl", size: 10 }],
    },
    {
        name: "data",
        children: [{ name: "Data", size: 10 }],
    },
    {
        name: "events",
        children: [{ name: "DataEvent", size: 10 }],
    },
    {
        name: "legend",
        children: [{ name: "Legend", size: 10 }],
    },
    {
        name: "operator",
        children: [{ name: "Legend", size: 10 }],
    },
]

const colors = [
    "#8889DD",
    "#9597E4",
    "#8DC77B",
    "#A5D297",
    "#E2CF45",
    "#F8C12D",
]

class CustomizedContent extends PureComponent {
    render() {
        const {
            root,
            depth,
            x,
            y,
            width,
            height,
            index,
            payload,
            colors,
            rank,
            name,
        } = this.props

        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill:
                            depth < 2
                                ? colors[
                                      Math.floor(
                                          (index / root.children.length) * 25
                                      )
                                  ]
                                : "none",
                        stroke: "#fff",
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10),
                    }}
                />
                {depth === 1 ? (
                    <text
                        x={x + width / 2}
                        y={y + height / 2 + 7}
                        textAnchor="middle"
                        fill="#fff"
                        fontSize={14}
                    >
                        {name}
                    </text>
                ) : null}
                {/* {depth === 1 ? (
                    <text
                        x={x + 4}
                        y={y + 18}
                        fill="#fff"
                        fontSize={16}
                        fillOpacity={0.9}
                    >
                        {index + 1}
                    </text>
                ) : null} */}
            </g>
        )
    }
}

export const HlaMatching = ({ data, colors }) => {
    return (
        <>
            {colors[0] === undefined ? (
                <></>
            ) : (
                <>
                    <div className="flex">
                        <Treemap
                            width={800}
                            height={400}
                            data={data}
                            dataKey="size"
                            ratio={4 / 3}
                            stroke="#fff"
                            fill="#8884d8"
                            content={<CustomizedContent colors={colors} />}
                        />
                        <div className="ml-10 flex-vertical text-white font-bold text-center leading-9">
                            <div className="m-3  w-10 h-10 bg-green-400">
                                16
                            </div>
                            <div className="m-3  w-10 h-10 bg-yellow-600">
                                18
                            </div>
                            <div className="m-3  w-10 h-10 bg-blue-600">12</div>
                            <div className="m-3  w-10 h-10 bg-orange-600">
                                8
                            </div>
                            <div className="m-3  w-10 h-10 bg-teal-600">6</div>
                            <div className="m-3  w-10 h-10 bg-pink-500">14</div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
