import React from "react"
import moment from "moment"
import {
    BarChart,
    ScatterChart,
    Label,
    Bar,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    ReferenceLine,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Brush,
    Cell,
    Text,
} from "recharts"

const renderLegend = (props) => {
    const { payload } = props

    return (
        <ul>
            {payload.map((entry, index) => (
                <div key={index} className="flex mt-7 justify-center">
                    <li className="mr-2">
                        <div className=" border-2 border-red-400 rounded w-3 h-3"></div>
                        <span>Tumor</span>
                    </li>
                    <li className="ml-2">
                        <div className=" border-2 border-green-700 rounded w-3 h-3"></div>
                        <span>Normal</span>
                    </li>
                </div>
            ))}
        </ul>
    )
}

export const TatReceipt = ({
    seqData,
    yAxisVar,
    xAxisVar,
    ydomain,
    refLine,
}) => {
    const dateFormatter = (date) => {
        return moment(date).format("YY-MM")
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload) {
            return (
                <>
                    <div className="tooltip-text">
                        {payload.map((row, index) => (
                            <p className="label" key={index}>
                                <>
                                    {row.name === xAxisVar ? (
                                        <>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {" "}
                                                {row.name}: &nbsp;
                                            </span>
                                            {moment(
                                                row.payload[xAxisVar]
                                            ).format("YY-MM-DD")}
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {row.name}: &nbsp;
                                            </span>
                                            {row.payload[yAxisVar]}
                                            <br></br>
                                            <br></br>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Type: &nbsp;
                                            </span>
                                            {row.payload.tumor_or_normal}
                                        </>
                                    )}
                                </>
                            </p>
                        ))}
                    </div>
                </>
            )
        }

        return null
    }
    return (
        <>
            <div className="responsive-container">
                <ResponsiveContainer width={"95%"} height={"100%"}>
                    <ScatterChart
                        data={seqData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid
                            strokeDasharray="5 5"
                            fill="#ececec"
                            stroke="black"
                        />

                        {typeof seqData[0] != "undefined" ? (
                            <>
                                <XAxis
                                    type="number"
                                    dataKey={xAxisVar}
                                    tickFormatter={dateFormatter}
                                    allowDuplicatedCategory={false}
                                    domain={["auto", "auto"]}
                                    scale="time"
                                >
                                    <Label
                                        value="TAT Receipt Date"
                                        // angle={-90}
                                        position="bottom"
                                        style={{
                                            textAnchor: "middle",
                                            fontWeight: "bold",
                                        }}
                                    />
                                </XAxis>
                            </>
                        ) : (
                            <></>
                        )}
                        <YAxis
                            domain={ydomain}
                            dataKey={yAxisVar}
                            type="number"
                        >
                            <Label
                                value={yAxisVar}
                                angle={-90}
                                position="left"
                                style={{
                                    textAnchor: "middle",
                                    fontWeight: "bold",
                                }}
                            />
                        </YAxis>
                        <ReferenceLine
                            y={refLine}
                            // label="Max"
                            stroke="darkred"
                            strokeWidth={2}
                            strokeDasharray="10 5"
                        />
                        <ZAxis range={[90, 91]} />
                        <Tooltip content={CustomTooltip} />
                        <Legend content={renderLegend} />
                        <Scatter fillOpacity="0" />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}
