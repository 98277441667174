import React from "react"
import Table from "react-bootstrap/Table"

export const RechartsTable = ({ data }) => {
    return (
        <>
            {data[0] !== undefined ? (
                <Table striped>
                    <thead>
                        <tr>
                            {Object.keys(data[0]).map((keys, idx) => (
                                <th key={idx}>{keys}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                {Object.keys(data[0]).map((keys, idx) => (
                                    <td key={idx}>{row[keys]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <></>
            )}
        </>
    )
}
