import react from "react"
import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import background from "./images/tdmp_header.png"
import { Row, Col } from "react-bootstrap"
import { NavTabs } from "./Components/Navigation/topTabs"
import { SideTabs } from "./Components/Navigation/sideTabs.js"
import { tableContent } from "./data/DataUploadCont"
import { Table } from "./Components/BlobTable/BlobTableStructure"
import { Hero } from "./Components/HomeIntro"
import { ToadTable } from "./Components/BlobTable"
// import calculatorData from "./data/ToadTableCalculator.json"
// import documentData from "./data/ToadTableDocument.json"

export const Home = () => {
    return (
        <div className="App">
            <img
                src={background}
                alt="backgroundImage"
                style={{ margin: 25 }}
            />
            <span className="font-link">
                <Row>
                    <SideTabs />
                    <Col xs={8}>
                        <NavTabs />
                        <Hero />
                        <Table body={tableContent} />
                        <ToadTable />
                    </Col>
                </Row>
            </span>
        </div>
    )
}
