import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import Dropdown from "react-bootstrap/Dropdown"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import axios from "axios"

export const TableElement = ({ itemHeader, modalHeader, dataType, img }) => {
    // handle Modal Popup
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    // handle Select Data
    const [selection, setSelection] = useState("")
    const handleSelection = (e) => {
        setSelection(e.target.value)
    }

    // handle CSV Upload
    const [csvFile, setCsvFile] = useState(false)
    const [calculatorUpdate, setCalculatorUpdate] = useState()

    let handleSubmit = async (e) => {
        e.preventDefault()
        var url = "https://tdmpdev.eastus.cloudapp.azure.com:5000" + selection
        const formData = new FormData(e.target) // pass the entire form
        try {
            setCalculatorUpdate(await axios.post(url, formData))

            // await axios.get(url).then((res) => {
            //     setCalculatorUpdate(res.data)
            // })
        } catch (err) {
            console.warn(err.toJSON())
        }
    }

    // Calculator Status Update
    // const [calculatorUpdate, setCalculatorUpdate] = useState([])
    // const calculatorUrl = "http://127.0.0.1:5000" + selection
    // useEffect(() => {
    //     fetch(calculatorUrl, {
    //         method: "GET",
    //         headers: {
    //             "Content-type": "application/json",
    //         },
    //     })
    //         .then((res) => res.json())
    //         .then((data) => {
    //             setCalculatorUpdate(data.status)
    //         })
    // }, [])

    return (
        <>
            <div
                style={{
                    width: "75px",
                }}
            >
                <Row
                    style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginBottom: "5px",
                    }}
                >
                    {itemHeader}
                </Row>
                <Row>
                    <img
                        onClick={() => handleShow()}
                        src={img}
                        alt={img}
                        className="image_button"
                        style={{
                            width: "100px",
                            padding: "10px",
                        }}
                    />
                </Row>
            </div>
            <Modal contentClassName='cal_modal_style' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={4}>Data Type</Col>
                            <Col xs={8}>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={handleSelection}
                                >
                                    <option>Select Data</option>
                                    {dataType.map((item, index) => (
                                        <option
                                            key={index}
                                            value={item.uploadURL}
                                        >
                                            {item.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={4}>Select Data File</Col>
                            <Col xs={8}>
                                <input
                                    type="file"
                                    name="file"
                                    className="form-control"
                                    onChange={(e) =>
                                        setCsvFile(e.target.files.length > 0)
                                    }
                                />
                            </Col>
                        </Row>
                        <button
                            type="submit"
                            className="btn-sm btn-success"
                            disabled={!csvFile}
                        >
                            Import
                        </button>
                    </form>
                </Modal.Body>
                {typeof calculatorUpdate === "undefined" ? (
                    <></>
                ) : (
                    <div>{calculatorUpdate.data.status}</div>
                )}
            </Modal>
        </>
    )
}
