import React from "react"
import {
    BarChart,
    ScatterChart,
    Label,
    Bar,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    ReferenceLine,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Brush,
    Cell,
    Text,
} from "recharts"
import * as d3 from "d3-array"

export const AnalysisHist = ({ seqData }) => {
    return (
        <>
            {seqData[0] !== undefined ? (
                <div className="responsive-container mb-10 mt-10">
                    <ResponsiveContainer width={"95%"} height={"100%"}>
                        <ScatterChart
                            data={seqData[1]}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 15,
                            }}
                        >
                            <XAxis
                                dataKey="peptide_count"
                                domain={["auto", "auto"]}
                                type="number"
                            >
                                <Label
                                    value="Peptide Count"
                                    // angle={-90}
                                    position="bottom"
                                    offset={0}
                                    style={{
                                        textAnchor: "middle",
                                        fontWeight: "bold",
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                dataKey="tmb_per_mb"
                                domain={["auto", "auto"]}
                                type="number"
                            >
                                <Label
                                    value="tmb_per_mb"
                                    angle={-90}
                                    position="left"
                                    offset={0}
                                    style={{
                                        textAnchor: "middle",
                                        fontWeight: "bold",
                                    }}
                                />
                            </YAxis>
                            <Scatter fill="green" />
                            <Tooltip />
                        </ScatterChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}
