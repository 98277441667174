import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import navContent from "../../data/navContent.json"
import Dropdown from "react-bootstrap/Dropdown"
import ButtonGroup from "react-bootstrap/ButtonGroup"

const Tabs = ({ content, link, external }) => {
    return (
        <div className="navTabs">
            {external ? (
                <a href={link} className="navTabsText">
                    {content}
                </a>
            ) : (
                <Link to={link} className="navTabsText">
                    {content}
                </Link>
            )}
        </div>
    )
}

function DropdownTab(data) {
    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle id="dropdownTabs">{data.data[0]}</Dropdown.Toggle>

            <Dropdown.Menu>
                {data.data[1].map((item, index) => (
                    <Dropdown.Item key={index} href={item.link}>
                        {item.content}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export const NavTabs = () => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    // border: "1px solid",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {navContent.map((item, index) => (
                    <span key={index}>
                        {item.constructor === Array ? (
                            <DropdownTab key={index} data={item} />
                        ) : (
                            <Tabs
                                link={item.link}
                                content={item.content}
                                key={index}
                                external={item.external}
                            />
                        )}
                    </span>
                ))}
            </div>
        </>
    )
}
