import react, { Component } from "react"
import { TableElement } from "../DataUploadDlg"

export class Table extends Component {
    render() {
        var body = this.props.body
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <table
                    style={{
                        width: 600,
                        height: 300,
                    }}
                >
                    <tbody>
                        {body.map((row, index) => (
                            <TableRow key={index} row={row} />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

class TableRow extends Component {
    render() {
        var row = this.props.row
        return (
            <tr>
                {row.map((itemDict, index) => (
                    <td key={index}>
                        <TableElement
                            itemHeader={itemDict.header}
                            key={index}
                            modalHeader={itemDict.modalHeader}
                            dataType={itemDict.dataType}
                            img={itemDict.img}
                        />
                    </td>
                ))}
            </tr>
        )
    }
}
