import React from "react"
import moment from "moment"
import {
    BarChart,
    RadarChart,
    Label,
    Bar,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    ReferenceLine,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Brush,
    Cell,
    Text,
    PolarRadiusAxis,
    PolarGrid,
    PolarAngleAxis,
} from "recharts"

export const Radar = () => {
    const data = [
        { N_DNA: 2, T_DNA: 1, T_RNA: 3, subject: "A1" },
        { N_DNA: 1, T_DNA: 3, T_RNA: 2, subject: "A2" },
        { N_DNA: 4, T_DNA: 4, T_RNA: 1, subject: "B1" },
        { N_DNA: 6, T_DNA: 8, T_RNA: 7, subject: "B2" },
    ]
    return (
        <>
            {/* <div className="responsive-container"> */}
            {/* <ResponsiveContainer width={"95%"} height={"100%"}> */}
            <RadarChart
                cx={300}
                cy={250}
                outerRadius={150}
                width={500}
                height={500}
                data={data}
            >
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                {/* <PolarRadiusAxis /> */}
                <Radar
                    name="Jake"
                    dataKey="N_DNA"
                    stroke="red"
                    fill="none"
                    fillOpacity={0.3}
                />
                <Radar
                    name="Jake"
                    dataKey="T_DNA"
                    stroke="blue"
                    fill="none"
                    fillOpacity={0.3}
                />
                <Radar
                    name="Jake"
                    dataKey="T_RNA"
                    stroke="green"
                    fill="none"
                    fillOpacity={0.3}
                />
            </RadarChart>
            {/* </ResponsiveContainer> */}
            {/* </div> */}
        </>
    )
}
