import * as React from "react"
import { Component } from "react"
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { Home } from "./Home"
import { TrendingReport } from "./bfxTrendingReport"

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/trending_report" element={<TrendingReport />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
