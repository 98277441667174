import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import axios from "axios"
import { Row, Col } from "react-bootstrap"
import { Routes, Route, useNavigate } from "react-router-dom"

export const TableModalAdd = () => {
    // handle Modal Popup
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    // handle Data update
    const [filename, setFilename] = useState()
    const [category, setCategory] = useState()
    const [description, setDescription] = useState()
    const [date, setDate] = useState()
    const [url, setUrl] = useState()

    const sqlQuery = `data=${filename},${category},${description},${date},${url}`

    let handleSubmit = async (e) => {
        e.preventDefault()
        var url = "https://tdmpdev.eastus.cloudapp.azure.com:5000/tdmp_blob_sql_add"
        // const formData = new FormData(e.target) // pass the entire form
        try {
            await axios.post(url, sqlQuery).then(handleClose()) // TODO: Refresh the TableComponent after submit. Currently page refresh is required
        } catch (err) {
            console.warn(err.toJSON())
        }
    }

    return (
        <> <div className="btn-sm btn-success">
            <button  onClick={handleShow}>
                Upload new
            </button>
            </div>

            <Modal contentClassName='cal_modal_style' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Data to BLOB</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={4}>Filename</Col>
                            <Col xs={8}>
                                <Form.Control
                                    onChange={(e) =>
                                        setFilename(e.target.value)
                                    }
                                />
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={4}>Category</Col>
                            <Col xs={8}>
                                <Form.Control
                                    onChange={(e) =>
                                        setCategory(e.target.value)
                                    }
                                />
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={4}>Description</Col>
                            <Col xs={8}>
                                <Form.Control
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={4}>Date</Col>
                            <Col xs={8}>
                                <Form.Control
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={4}>Version</Col>
                            <Col xs={8}>
                                <Form.Control
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </Col>
                        </Row> <br></br>
                        <Row>
                            <Col xs={4}>URL</Col>
                            <Col xs={8}>
                                <Form.Control
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <br></br>
                        <button type="submit" className="btn-sm btn-success">
                            Upload
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
