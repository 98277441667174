import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Row } from "react-bootstrap"
import Table from "react-bootstrap/Table"
import { TableModal } from "./BlobTable/BlobUpdateDlg"
import { TableModalAdd } from "./BlobTable/BlobAddDlg"

const FooterTable = ({ data, name }) => {
    return (
        <>
            <p className="h3">{name}</p>

            <Table className="border table-hover" style={{ width: 850 }}>
                <thead>
                    <tr>
                        <th className="text-xsm w-44" style={{ width: 260 }}>
                            File Name
                        </th>
                        <th
                            className="text-xsm text-left"
                            style={{ width: 650 }}
                        >
                            Description
                        </th>
                        <th
                            className="text-xsm text-left"
                            style={{ width: 20 }}
                        >
                            Version
                        </th>
                        <th
                            className="text-xsm text-right"
                            style={{ width: 150 }}
                        >
                            Date
                        </th>
                        <th
                            className="text-xsm text-left"
                            style={{ width: 130 }}
                        >
                            Update
                        </th>
                    </tr>
                </thead>
                {data.map((item, index) => (
                    <tbody key={index} className="text-xsm text-left">
                        <tr>
                            <td>
                                <Link
                                    to={item[6]}
                                    target="_blank"
                                    className="leading-4 text-black"
                                    rel="noopener noreferer"
                                >
                                    {item[1]}
                                </Link>
                            </td>
                            <td>{item[3]}</td>
                            <td>{item[4]}</td>
                            <td className="text-right ">{item[5]}</td>
                            <td>
                                <TableModal data={item} />
                            </td>
                        </tr>
                    </tbody>
                ))}
                <tbody className="text-xsm text-left">
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <TableModalAdd data={data} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export const ToadTable = () => {
    const [calculatorData, setCalculatorData] = useState([])
    const [documentData, setDocumentData] = useState([])
    useEffect(() => {
        fetch("https://tdmpdev.eastus.cloudapp.azure.com:5000/tdmp_blob_tab", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setCalculatorData(data.calculatorData)
                setDocumentData(data.documentData)
            })
    }, [])
    return (
        <>
            <div className="mt-10 flex">
                <p className="h2">Documents and Calculators</p>
            </div>
            <br></br>
            <FooterTable name="Calculators" data={calculatorData} />
            <FooterTable name="Documents" data={documentData} />
        </>
    )
}
