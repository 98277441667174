import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Col } from "react-bootstrap"
import sideContent from "../../data/sideContent.json"

const Tabs = ({ content, link, external }) => {
    return (
        <>
            {external ? (
                <a href={link} className="sideTabs">
                    {content}
                </a>
            ) : (
                <Link to={link} className="sideTabs">
                    {content}
                </Link>
            )}
        </>
    )
}

export const SideTabs = () => {
    return (
        <>
            <Col xs={2} className="flex items-center flex-col" style={{marginTop:100}} >
                <p className="s-14 font-bold">Quick Links</p>
                {sideContent.map((item) => (
                    <Tabs
                        link={item.link}
                        content={item.content}
                        key={item.content}
                        external={item.external}
                    />
                ))}
            </Col>
        </>
    )
}
