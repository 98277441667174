import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import tdmp from "../images/tdmp.png"


export const Hero = ({ content, link }) => {
    return (
        <>
            <div> <br></br>
                <Row
                    style={{
                        fontWeight: "bold",
                        marginTop: "25px",
                    }}
                >
                    <Col xs={2}>
                        <img src={tdmp} alt="tdmp" style={{ width: "100px" }} />
                    </Col>
                    <Col xs={10}> <p className="h1" style={{ width: "700px", textAlign: "left", marginBottom: "20px" }}>TIDAL-Data Management Platform - T-DMP </p></Col>
                </Row>
                <Row>
                    <p style={{ fontSize: "15px", marginTop: "20px", textAlign: "left" }}>
                        <br></br>This site is for curation and querying TIDAL QC-ALCM
                        data. Please report any errors, broken links, or feature
                        request to{" "}
                        <a
                            href="mailto:tony.collins@turnstonebio.com"
                            style={{ color: "blue" }}
                        >
                            Tony Collins
                        </a>
                    </p>
                    <br></br>
                </Row>
                <p className="h2"   style={{                       
                        marginTop: "25px",
                    }}>
                   Data upload
                </p>
                <p style={{ fontSize: "15px",  textAlign: "left" }}>
                Select buttons below to upload data files. </p>
            </div>
        </>
    )
}
