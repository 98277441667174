import bfx from "../images/bfx.png"
import cell_counts from "../images/cell_counts.png"
import flowcyt from "../images/flowcyt.png"
import potency from "../images/potency.png"
import mfg from "../images/mfg.png"
import safety from "../images/safety.png"
export const tableContent = [
    [
        {
            key: "1",
            header: "Bioinformatics",
            modalHeader: "Upload BFX results to T-DMP",
            img: bfx,
            dataType: [
                {
                    value: 1,
                    label: "BFX QC Table *.tsv",
                    uploadURL: "/bfx_runtime_file",
                },
                {
                    value: 2,
                    label: "BFX Annotated Peptide list*.csv",
                    uploadURL: "/bfx_pep_file_singles",
                },
                {
                    value: 3,
                    label: "Tempus SOW3 report*.xlsx",
                    uploadURL: "/seq_report",
                },
                {
                    value: 4,
                    label: "Almac TUR* peptide file",
                    uploadURL: "/mfg_pep_file",
                },
            ],
        },
        {
            key: "2",
            header: "Cell Count Data",
            modalHeader: "Upload Cell Count Data file to T-DMP",
            img: cell_counts,
            dataType: [
                {
                    value: 1,
                    label: "CRL-MFG-NC200",
                    uploadURL: "/upload_mfg_count",
                },
                {
                    value: 1,
                    label: "CRL-QC-NC200",
                    uploadURL: "/upload_qc_count",
                },
            ],
        },
        {
            key: "3",
            header: "Cell Phenotyping",
            modalHeader: "Upload Flow Cytometry Data file to T-DMP",
            img: flowcyt,
            dataType: [
                {
                    value: 1,
                    label: "CRL FLOW Release panel",
                    uploadURL: "/crl_flow_rel",
                },
                {
                    value: 1,
                    label: "CRL FLOW Impurity panel",
                    uploadURL: "/crl_qc_imp_flow",
                },
                {
                    value: 1,
                    label: "CRL FLOW DC diff panel",
                    uploadURL: "/crl_qc_dcdiff_flow",
                },
            ],
        },
   
        {
            key: "4",
            header: "Oncopotency",
            modalHeader: "TODO",
            img: potency,
            dataType: [],
        },
    ],
    [
        {
            key: "5",
            header: "BPR Upload",
            modalHeader: "Upload MFG Data file to T-DMP",
            img: mfg,
            dataType: [
                {
                    value: 1,
                    label: "CRL BPR file",
                    uploadURL: "/crl_upload_bpr",
                },
                {
                    value: 2,
                    label: "MCC BPR file",
                    uploadURL: "/mcc_upload_bpr",
                },
            ],
        },
        {
            key: "6",
            header: "Safety Testing",
            modalHeader: "Upload Safety testing data to DMP",
            img: safety,
            dataType: [
                {
                    value: 1,
                    label: "Endotoxin",
                    uploadURL: "/endotoxin_upload",
                },
                {
                    value: 2,
                    label: "MycoSeq",
                    uploadURL: "/mycoseq_upload",
                },
            ],
        },
    ],
]
