import React from "react"
import moment from "moment"
import {
    BarChart,
    ScatterChart,
    Label,
    Bar,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    ReferenceLine,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
    Brush,
    Cell,
    Text,
} from "recharts"
import { PatternFormat } from "react-number-format"

const renderLegend = (props) => {
    const { payload } = props

    return (
        <ul>
            {payload.map((entry, index) => (
                <div key={index} className="flex mt-7 justify-center">
                    <li className="mr-2">
                        <div className=" border-2 border-green-400 rounded w-3 h-3"></div>
                        <span>N_DNA</span>
                    </li>
                    <li className="ml-2">
                        <div className=" border-2 border-red-700 rounded w-3 h-3"></div>
                        <span>T_DNA</span>
                    </li>
                    <li className="ml-2">
                        <div className=" border-2 border-purple-400 rounded w-3 h-3"></div>
                        <span>T_RNA</span>
                    </li>
                </div>
            ))}
        </ul>
    )
}

export const SeqFileCreation = ({ seqData, yAxisVar, ydomain, refLine }) => {
    const dateFormatter = (date) => {
        return moment(date).format("YY-MM")
    }

    const yaxisFormatter = (yValue) => {
        if (yValue > 1e5) {
            return Number.parseFloat(yValue).toExponential(0)
        } else {
            return yValue
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload) {
            return (
                <>
                    <div className="tooltip-text">
                        {payload.map((row, index) => (
                            <p className="label" key={index}>
                                <>
                                    {row.name === "file_creation_date" ? (
                                        <>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {row.name}: &nbsp;
                                            </span>
                                            {moment(
                                                row.payload.file_creation_date
                                            ).format("YY-MM-DD")}
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {row.name}: &nbsp;
                                            </span>
                                            {row.payload[yAxisVar]}
                                            <br></br>
                                            <br></br>
                                            <span
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Type: &nbsp;
                                            </span>
                                            {row.payload.file_category}
                                        </>
                                    )}
                                </>
                            </p>
                        ))}
                    </div>
                </>
            )
        }

        return null
    }
    return (
        <>
            <div className="responsive-container">
                {/* {console.log(seqData)} */}
                <ResponsiveContainer width={"95%"} height={"100%"}>
                    <ScatterChart
                        data={seqData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            type="number"
                            dataKey="file_creation_date"
                            tickFormatter={dateFormatter}
                            allowDuplicatedCategory={false}
                            domain={["auto", "auto"]}
                            // scale="time"
                        >
                            <Label
                                value="Seq File Creation Date"
                                position="bottom"
                                style={{
                                    textAnchor: "middle",
                                    fontWeight: "bold",
                                }}
                            />
                        </XAxis>
                        <YAxis
                            domain={ydomain}
                            dataKey={yAxisVar}
                            type="number"
                            tickFormatter={yaxisFormatter}
                        >
                            <Label
                                value={yAxisVar}
                                angle={-90}
                                position="left"
                                style={{
                                    textAnchor: "middle",
                                    fontWeight: "bold",
                                }}
                            />
                        </YAxis>
                        {typeof refLine === "number" ? (
                            <ReferenceLine
                                y={refLine}
                                // label="Max"
                                stroke="darkred"
                                strokeWidth={2}
                                strokeDasharray="10 5"
                            />
                        ) : (
                            <>
                                <ReferenceLine
                                    y={refLine[0]}
                                    // label="Max"
                                    stroke="darkred"
                                    strokeWidth={2}
                                    strokeDasharray="10 5"
                                />
                                <ReferenceLine
                                    y={refLine[1]}
                                    // label="Max"
                                    stroke="darkred"
                                    strokeWidth={2}
                                    strokeDasharray="10 5"
                                />
                            </>
                        )}

                        <ZAxis range={[90, 91]} />
                        <Tooltip content={CustomTooltip} />
                        <Legend content={renderLegend} />
                        <Scatter fillOpacity="0" />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}
