import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import moment from "moment"
import tdmpHeader from "./images/tdmp_header.png"
import { SampleSeq } from "./Components/Recharts/seqDataCountBar"
import { TatReceipt } from "./Components/Recharts/seqTatDate"
import { SeqFileCreation } from "./Components/Recharts/bfxSACDate"
import { HlaMatching } from "./Components/Recharts/hlaMatching"
import { RechartsTable } from "./Components/Recharts/bfxRuntimeTable"
import { YieldReads } from "./Components/Recharts/yieldReads"
import { AnalysisHist } from "./Components/Recharts/analysisHist"
import { Radar } from "./Components/Recharts/radar"

export const TrendingReport = () => {
    const [seqCounter, setSeqCounter] = useState([])
    const [seqData, setSeqData] = useState([])
    const [critVal, setCritVal] = useState([])
    const [fileCreation, setFileCreation] = useState([])
    const [textValues, setTextValues] = useState([])
    const [runtimeTable, setRuntimeTable] = useState([])
    const [HlaTreemap, setHlaTreemap] = useState([])
    const [HlaColors, setHlaColors] = useState([])
    const [HlaUnmatched, setHlaUnmatched] = useState([])
    const [peptideCountAnalysis, setPeptideCountAnalysis] = useState([])
    const [yieldsReads, setYieldsReads] = useState([])
    const [rechartsRadar, setRechartsRadar] = useState([])
    const [patientsUnmatched, setPatientsUnmatched] = useState([])

    const dateSpread = (data, dateVar) => {
        data.forEach((d) => {
            d[dateVar] = moment(d[dateVar]).valueOf() // date -> epoch
        })
        return data
    }

    useEffect(() => {
        fetch("https://tdmpdev.eastus.cloudapp.azure.com:5000/trending_report")
            .then((res) => res.json())
            .then((data) => {
                setSeqCounter(data.samples_sequenced)
                setTextValues(data.text_values)
                setSeqData(dateSpread(data.plot_1, "receipt_date"))
                setCritVal(dateSpread(data.crit_values, "receipt_date"))
                setFileCreation(
                    dateSpread(data.file_creation_dict, "file_creation_date")
                )
                setRuntimeTable(data.df_runtime_table)
                setHlaTreemap(data.hla_treemap[0])
                setHlaColors(data.hla_treemap[1])
                setHlaUnmatched(data.df_hla_unmatched)
                setPeptideCountAnalysis(data.peptide_count_analysis)
                setYieldsReads(data.yields_reads)
                setRechartsRadar(data.recharts_radar_all[1])
                setPatientsUnmatched(data.recharts_radar_all[0])
            })
    }, [])

    const HlaUnmatchedUpd = JSON.parse(
        JSON.stringify(
            HlaUnmatched,
            [
                "patient_id",
                "hla_material",
                "hla_a1",
                "hla_a2",
                "hla_b1",
                "hla_b2",
                "hla_c1",
                "hla_c2",
            ],
            4
        )
    )

    const tat_ydomain = [0, 100]
    const refLine = [14, 50, 50, 175, 150]
    const crit_ydomain_dna1 = [0, 35000]
    const crit_ydomain_rna1 = [0, 5000]
    const crit_ydomain_dna2 = [0, 3800]
    const crit_ydomain_rna2 = [0, 1300]
    const file_creation_gc = [35, 60]
    const file_creation_gte = [80, 100]
    const file_creation_bases_n = [0, 0.1]
    const file_creation_pairs = [3e6, 6e8]
    const file_creation_depth = [100, 600]
    const file_creation_post_dedup = [10, 70]
    const file_creation_coverage = [0, 700]
    const file_creation_read_counts = [3e6, 3e8]
    const file_creation_ref = [[35, 60], 80, 5, 6000000, 120, 30, 120, 6000000]
    const yieldsReadsDna1 = [0, 40000]
    const yieldsReadsDna2 = [0, 4000]
    const yieldsReadsRna1 = [0, 6500]
    const yieldsReadsRna2 = [0, 1000]

    return (
        <>
            <div className="ml-20 mt-10">
                <img src={tdmpHeader} alt={tdmpHeader} className="m-10" />
                <h3>TBio-4101-BFX trending report</h3>
                <h5 className="font-bold"> Data Summary </h5>
                <p>
                    Number of Patients sequencing completed by Tempus (from 28d
                    report) = <span className="font-bold">{textValues[0]}</span>
                </p>
                <p>
                    Number of Patients sequencing completed by Tempus (from 28d
                    report) = <span className="font-bold">{textValues[1]}</span>
                </p>
                <p>
                    Number of files processed with Tbio-4101-BFX pipeline (6 per
                    patient) ={" "}
                    <span className="font-bold">{textValues[2]}</span>
                </p>

                <h5 className="font-bold"> Tempus Sequencing Report </h5>
                <p>
                    Tempus are contracted to a TAT of 14d for clinical samples.
                    TATs are calculated based on Sample Receipt times provided
                    by Tempus vs Sequence file creation dates. The file creation
                    date is extracted from the Tempus filenames.The sample
                    receipt date is obtained from Tempus "28d reports".
                </p>
                <div style={{ marginTop: "40px" }}></div>
                <SampleSeq importData={seqCounter} />
                <TatReceipt
                    xAxisVar="receipt_date"
                    seqData={seqData}
                    yAxisVar="file_tat"
                    ydomain={tat_ydomain}
                    refLine={refLine[0]}
                />
                <h5 className="font-bold">Tempus Seq QC criteria values</h5>
                <p>
                    Tempus has several quality checkpoints in their process.
                    <span className="italic">
                        <br />
                        Q1 = Extraction step <br />
                        Q2 = Library Prep step <br />
                        Q3 = hybridization and capture <br />
                        Q4 = read count post sequencing The acceptance criteria
                        are marked in red-dashed lines
                    </span>
                </p>
                <div className="flex">
                    <TatReceipt
                        xAxisVar="receipt_date"
                        seqData={critVal}
                        yAxisVar="dna_qc1_yield_ng"
                        ydomain={crit_ydomain_dna1}
                        refLine={refLine[1]}
                    />
                    <TatReceipt
                        xAxisVar="receipt_date"
                        seqData={critVal}
                        yAxisVar="rna_qc1_yield_ng"
                        ydomain={crit_ydomain_rna1}
                        refLine={refLine[2]}
                    />
                </div>
                <div className="flex">
                    <TatReceipt
                        xAxisVar="receipt_date"
                        seqData={critVal}
                        yAxisVar="dna_qc2_yield_ng"
                        ydomain={crit_ydomain_dna2}
                        refLine={refLine[3]}
                    />
                    <TatReceipt
                        xAxisVar="receipt_date"
                        seqData={critVal}
                        yAxisVar="rna_qc2_yield_ng"
                        ydomain={crit_ydomain_rna2}
                        refLine={refLine[4]}
                    />
                </div>
                <h5 className="font-bold">Runtime Summary</h5>
                <p>
                    Summary of run-times for the various tools in the
                    TBio-410-BFX pipeline.
                </p>
                <RechartsTable data={runtimeTable} />
                <h5 className="font-bold">
                    System Acceptance Criteria and their Tolerance Intervals
                </h5>
                <p>
                    Acceptance criteria sequence metrics for all PD and MFG
                    datasets. System Acceptance Criteria (SAC) are shown as
                    dashed red lines; calculated 90% tolerance intervals (TI)
                    are shown in blue. For each patient, six data-files are
                    generated: two each for Tumor WES (T_DNA), normal WES
                    (N_DNA), and Tumor RNAseq (T_RNA).
                </p>
                <div className="flex">
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="perc_gc_content"
                        ydomain={file_creation_gc}
                        refLine={file_creation_ref[0]}
                    />
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="perc_bases_gte_q30"
                        ydomain={file_creation_gte}
                        refLine={file_creation_ref[1]}
                    />
                </div>
                <div className="flex">
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="perc_bases_with_n"
                        ydomain={file_creation_bases_n}
                        refLine={file_creation_ref[2]}
                    />
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="unique_read_pairs_num"
                        ydomain={file_creation_pairs}
                        refLine={file_creation_ref[3]}
                    />
                </div>
                {/* TODO: Insert Table2 */}
                <h5 className="font-bold">
                    Secondary metrics and their Tolerance Intervals
                </h5>
                <p>
                    Criteria that are “to-report” and trended in anticipation of
                    setting future acceptance criteria. Calculated 90% tolerance
                    intervals (TI) are shown in blue. Desired acceptance
                    criteria in green. For each patient, six data-files are
                    generated: two each for Tumor WES (T_DNA), normal WES
                    (N_DNA), and Tumor RNAseq (T_RNA).
                </p>
                <div className="flex">
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="read_depth_int"
                        ydomain={file_creation_depth}
                        refLine={file_creation_ref[4]}
                    />
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="perc_reads_post_deduplication"
                        ydomain={file_creation_post_dedup}
                        refLine={file_creation_ref[5]}
                    />
                </div>
                <div className="flex">
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="avg_coverage_int"
                        ydomain={file_creation_coverage}
                        refLine={file_creation_ref[6]}
                    />
                    <SeqFileCreation
                        seqData={fileCreation}
                        yAxisVar="filtered_read_counts_num"
                        ydomain={file_creation_read_counts}
                        refLine={file_creation_ref[7]}
                    />
                </div>
                {/* TODO: Insert Table3 */}

                <div className="mb-10">
                    <h5> HLA matching</h5>
                    The peptides list generated in the TBio-4110-BFX pipeline
                    uses the Patient HLA to rank peptides based on their
                    'likelihood' to be neoantigens. <br></br>This 'likelihood'
                    is determined by peptide binding affinity to the patient MHC
                    I complex. <br></br>For the TBio-4101-BFX piplein, Tumor DNA
                    is used to derive the HLA for the Patient. The rationale for
                    this is:
                    <ul>
                        <li>
                            1. The neoantigens of interest are presented by
                            tumour cells
                        </li>
                        <li>
                            2. The tumor DNA is sequenced at a greater depth
                            than the normal counterpart
                        </li>
                    </ul>
                    For retrospective meta-analysis, we calculate patient HLA
                    using three strategies
                    <ul>
                        <li>1. Tumour DNA </li> <li>2. Normal DNA</li>
                        <li> 3. Tumour RNA</li>
                    </ul>
                    The results below indicate concordance between six MHC I
                    alleles per patient in three samples. A match-score of 18
                    represents all six MHC I alleles matching across all three
                    approaches. <br></br>A match-score of 18 between two
                    typing-strategies represents a HLA calling identical to
                    4-digits for the three strategies match. Below 18 represents
                    discordant calling of HLA between the three strategies.
                </div>
                <HlaMatching data={HlaTreemap} colors={HlaColors} />
                <RechartsTable data={HlaUnmatchedUpd} />
                <h5 className="mt-10">Analysis of Peptide Count</h5>
                <p>TMB = tumor mutational burden</p>
                <AnalysisHist seqData={peptideCountAnalysis} />
                <h5 className="mb-10">Yields vs Reads</h5>
                <div className="flex">
                    <YieldReads
                        xAxisVar="num_of_reads_dna"
                        seqData={yieldsReads}
                        yAxisVar="dna_qc1_yield_ng"
                        ydomain={yieldsReadsDna1}
                        // refLine={refLine[3]}
                    />
                    <YieldReads
                        xAxisVar="num_of_reads_dna"
                        seqData={yieldsReads}
                        yAxisVar="dna_qc2_yield_ng"
                        ydomain={yieldsReadsDna2}
                        // refLine={refLine[4]}
                    />
                </div>
                <div className="flex">
                    <YieldReads
                        xAxisVar="num_of_reads_rna"
                        seqData={yieldsReads}
                        yAxisVar="rna_qc1_yield_ng"
                        ydomain={yieldsReadsRna1}
                        // refLine={refLine[3]}
                    />
                    <YieldReads
                        xAxisVar="num_of_reads_rna"
                        seqData={yieldsReads}
                        yAxisVar="rna_qc2_yield_ng"
                        ydomain={yieldsReadsRna2}
                        // refLine={refLine[4]}
                        dateRequired="false"
                    />
                </div>
                <Radar data={rechartsRadar[1]} />
            </div>
        </>
    )
}
